<template>
  <v-app>
    <div class="immoapp__search" data="app">
      <v-row>
        <v-col cols="24" lg="18">
          <v-form @submit.prevent="search">
            <v-row>
              <v-col cols="12" md="12" lg="6">
                <v-select
                  :items="marketingTypes"
                  label="Kauf & Miete"
                  v-model="marketingType"
                  filled
                  flat
                  clearable
                  attach
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" md="12" lg="6">
                <v-select
                  :items="categories"
                  v-model="category"
                  label="Kategorie"
                  :return-object="true"
                  filled
                  flat
                  clearable
                  attach
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" md="12" lg="6">
                <v-select
                  :items="usageTypes"
                  v-model="usageType"
                  label="Nutzungsart"
                  filled
                  flat
                  clearable
                  attach
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" md="12" lg="6">
                <button type="submit" class="btn btn-nomw btn-block btn-primary btn-search">
                  Suchen
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col cols="24" lg="6">
          <div class="wp-block-button mt-3 mt-lg-0">
            <a
              class="wp-block-button__link wp-element-button btn-search"
              href="?_search=true&amp;usageType=anlage"
              >Kapitalanlagen</a
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import inquiryFieldsConfig from "./utils/InquiryFieldsConfig";
import config from "./ConfigSearch";

export default {
  name: "SearchApp",

  props: ["categories", "params", "action", "usageTypes"],

  data: () => ({
    marketingType: "",
    usageType: "",
    category: "",
    rooms: "",
    price: null,
    radius: null,
    customObjectId: null,
    categoryId: null,
    livingSpace: null,
    plotArea: null,
    totalArea: null,
    address: "",
    addressModel: "",
    lat: null,
    lng: null,
    city: null,
    zip: null,
    sort: null,
    marketingTypes: [
      {
        text: "Kauf",
        value: "buy",
      },
      {
        text: "Miete",
        value: "rent",
      },
    ],
    radiusList: [
      {
        text: "5 km",
        value: 5,
      },
      {
        text: "10 km",
        value: 10,
      },
      {
        text: "15 km",
        value: 15,
      },
      {
        text: "25 km",
        value: 25,
      },
      {
        text: "35 km",
        value: 35,
      },
    ],
    fields: [],
    fieldsConfig: [],
    config,
  }),

  computed: {
    detailFields() {
      let fields = this.fields;
      for (let i in fields) {
        var value = "value";
        var name = fields[i].id;
        if (fields[i]["range"] == "max") {
          value = "maxValue";
        } else if (fields[i]["range"] == "min") {
          value = "minValue";
        }

        if (name == "price.value") {
          name = "price";
        } else {
          name = name.substr(name.indexOf(".") + 1);
        }

        fields[i]["ds"][value] = this[name];
      }

      return fields;
    },

    selectedFields() {
      if (this.category) {
        let fieldNames = [];
        config.categoryList.forEach((c) => {
          if (c.name == this.category.slug) {
            c.fieldList.forEach((f) => {
              if (typeof f == "object") {
                fieldNames.push(f.var);
              } else {
                fieldNames.push(f);
              }
            });
          }
        });

        return this.detailFields.filter((f) => {
          return fieldNames.indexOf(f.id) > -1;
        });
      }

      return this.detailFields.slice(0, 4);
    },
  },

  mounted() {
    Object.keys(this.params).forEach((k) => {
      if (this.params[k].value) {
        this[k] = this.params[k].value;
      }

      if (k == "categoryId") {
        for (let i in this.categories) {
          if (this.categories[i].categoryId == this.categoryId) {
            this.category = this.categories[i];
          }
        }
      }
    });

    this.addressModel = {
      text: this.address,
      value: this.address,
      zip: this.zip,
      city: this.city,
      lat: this.lat,
      lng: this.lng,
    };

    inquiryFieldsConfig(config, (t) => {
      return t;
    }).then((fieldsConfig) => {
      this.fields = fieldsConfig;
    });
  },

  methods: {
    changeField(field) {
      //const fields = [];
      let fields = this.selectedFields;
      var name = null;
      var value = null;

      for (let i in fields) {
        if (fields[i].id == field.id) {
          name = field.id;
          if (fields[i]["range"] == "max") {
            value = fields[i]["ds"]["maxValue"];
          } else if (fields[i]["range"] == "min") {
            value = fields[i]["ds"]["minValue"];
          }

          if (name == "price.value") {
            name = "price";
          } else {
            name = name.substr(name.indexOf(".") + 1);
          }
        }
      }

      this[name] = value;
    },

    updateGeo(geo) {
      if (geo) {
        this.lat = geo.lat;
        this.lng = geo.lng;
        this.address = geo.text;
        this.zip = geo.zip;
        this.city = geo.city;

        if (!this.radius) {
          this.radius = this.radiusList[0].value;
        }
      } else {
        this.lat = null;
        this.lng = null;
        this.address = null;
        this.radius = null;
        this.zip = null;
        this.city = null;
      }
    },

    search() {
      const params = new URLSearchParams();

      if (this.category) {
        this.categoryId = this.category.categoryId;
      } else {
        this.categoryId = null;
      }

      Object.keys(this.params).forEach((k) => {
        if (k != "paged") {
          if (this[k] || this[k] === "") {
            if (k == "sort") {
              params.append(this.params[k].name, this[k].field + "|" + this[k].order.toLowerCase());
            } else {
              params.append(this.params[k].name, this[k]);
            }
          }
        }
      });

      let action = this.action;

      window.location.href = action + "?" + params.toString();
    },
  },
};
</script>
