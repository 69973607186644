import deps from "../depsloader";

export default () => {
  const elms = document.querySelectorAll(".swiper-container");
  if (!elms || elms.length == 0) {
    return;
  }

  deps.load("swiper").then((swiper) => {
    const Swiper = swiper.Swiper;
    elms.forEach((elm) => {
      let config = {};

      if (elm.dataset.config) {
        config = JSON.parse(elm.dataset.config);
        elm.removeAttribute("data-config");
      }

      if (elm.dataset.lg) {
        deps.load("lightgallery").then((lg) => {
          let lgConfig = {
            licenseKey: 'info@screenwork.de'
          };
          if (elm.dataset.lg) {
            lgConfig = {...lgConfig, ...JSON.parse(elm.dataset.lg)};
            elm.removeAttribute("data-lg");
          }

          lg.lg(elm, lgConfig); // eslint-disable-line
        });
      }

      new Swiper(elm, config);
    });
  });
}
