<template>
<div>
  <h2 class="mb-1 h3">Daten des Tippgebers</h2>
  <div class="row row-small">
    <div class="col-md-8">
      <contact-select name="salutation" :label="$t('Anrede')" group="contact" :values="$t('Frau,Herr')" :placeholder="$t('Anrede')"></contact-select>
    </div>
    <div class="col-md-8">
      <contact-input name="firstname" :label="$t('Vorname')" group="contact"></contact-input>
    </div>
    <div class="col-md-8">
      <contact-input name="lastname" :label="$t('Nachname')" group="contact"></contact-input>
    </div>

    <div class="col-24">
      <contact-input name="phone" :label="$t('Telefon')" group="contact"></contact-input>
    </div>
    <div class="col-24" v-if="exclude.indexOf('email') == -1">
      <contact-input name="email" :label="$t('E-Mail')" group="contact"></contact-input>
    </div>

    <div class="col-24">
      <contact-select name="interesseAn" :label="$t('Unser nachstehend genannter Kunde hat Interesse an')" group="contact"
                      :values="$t('Verkauf einer Immobilie|verkaufEinerImmobilie, Kauf einer Immobilie|kaufEinerImmobilie')"></contact-select>
    </div>
  </div>

  <h2 class="mb-1 h3">Kundendaten</h2>
  <div class="row row-small">
    <div class="col-md-8">
      <contact-select name="salutationKunde" :label="$t('Anrede')" group="contact" :values="$t('Frau,Herr')" :placeholder="$t('Anrede')"></contact-select>
    </div>
    <div class="col-md-8">
      <contact-input name="firstnameKunde" :label="$t('Vorname')" group="contact"></contact-input>
    </div>
    <div class="col-md-8">
      <contact-input name="lastnameKunde" :label="$t('Nachname')" group="contact"></contact-input>
    </div>

    <div class="col-md-8">
      <contact-input name="streetKunde" :label="$t('Straße, Nr.')" group="contact"></contact-input>
    </div>
    <div class="col-md-8">
      <contact-input name="zipKunde" :label="$t('PLZ')" group="contact"></contact-input>
    </div>
    <div class="col-md-8">
      <contact-input name="cityKunde" :label="$t('Ort')" group="contact"></contact-input>
    </div>

    <div class="col-24">
      <contact-input name="phoneKunde" :label="$t('Telefon*')" group="contact"></contact-input>
    </div>
    <div class="col-24" v-if="exclude.indexOf('email') == -1">
      <contact-input name="emailKunde" :label="$t('E-Mail*')" group="contact"></contact-input>
    </div>

    <div class="col-24">
      <contact-select name="wasWirdGesucht" :label="$t('Welche Immobilie wird gesucht oder angeboten?')" group="contact"
                      :values="$t('Neubau-ETW mit Sicherheits- und Sorgloskonzept und 10 Jähriger Mietgarantie|neubauETW10JahreGarantie, ETW|etw, Einfamilienhaus|einfamilienhaus, Mehrfamilienhaus|mehrfamilienhaus')"></contact-select>
    </div>

    <div class="col-sm-24">
      <div class="contact__center-checkbox">
        <p><strong>Verwendungzweck</strong></p>
        <v-checkbox v-model="contactUseFor" hide-details :label="$t('Kapitalanlage')" value="zur Kapitalanlage" off-icon="far fa-square" on-icon="fas fa-check-square"></v-checkbox>
        <v-checkbox v-model="contactUseFor" hide-details :label="$t('Selbstnutzung')" value="zur Selbstnutzung" off-icon="far fa-square" on-icon="fas fa-check-square"></v-checkbox>
      </div>
    </div>

    <div class="col-sm-24">
      <contact-input  textarea :label="$t('Besondere Hinweise/Wünsche')" name="message" group="contact"></contact-input>
      <p class="text-small mb-1"><i class="fas fa-info-circle fa-fw text-primary"></i>Ihre Kundenschutzbestätigung bekommen Sie sofort nach Überprüfung per E-Mail zugesandt.</p>
    </div>

    <v-checkbox hide-details v-model="allowPhoneContact" :error="this.$root.contactForm.error && !allowPhoneContact" off-icon="far fa-square" on-icon="fas fa-check-square">
      <template v-slot:label>
        <div class="text-xsmall mb-0">
          Der Kunde erklärt sich damit einverstanden, dass er telefonisch zum Zwecke einer Beratung kontaktiert wird.
        </div>
      </template>
    </v-checkbox>
  </div>

</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactSelect from "./ContactSelect.vue";

export default {
  name: "ContactFields",

  components: {
    ContactInput,
    ContactSelect
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      contactUseFor: [],
      allowPhoneContact: false
    };
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    }
  },

  mounted() {
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      this.$root.contactForm.contact.contactUseFor = this.contactUseFor;
      this.$root.contactForm.contact.allowPhoneContact = this.allowPhoneContact;
    }
  }
};
</script>
