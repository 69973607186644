import Vue from "vue";
import CounterApp from "../components/counter/CounterApp.vue";

export default () => {
  const numbers = document.querySelectorAll(".counter .number");
  if (!numbers) {
    return;
  }

  for(const element of numbers) {
    new Vue({
      el: element,

      components: {
        CounterApp
      },

      computed: {
        numberValue() {
          if (element.dataset.numbervalue) {
            return element.dataset.numbervalue;
          }

          return 0;
        },

        fixedValue() {
          if (element.dataset.fixed) {
            return element.dataset.fixed;
          }

          return false;
        }
      },

      render: h => h(CounterApp)
    });
  }
}
