<template>
<div>
  <div>
    <v-checkbox
      v-model="moreProperties"
      label="Bitte bieten Sie mir auch weitere, interessante Immobilienangebote an."
    ></v-checkbox>
  </div>

  <div>
    <v-checkbox
      v-model="newsletter"
      label="Ich bin an Ihren Newslettern zu aktuellen Immobilienangeboten und allgemeinen Informationen zum Immobilienmarkt, z.B. regelmäßige Wohn- und Gewerbemarktberichten, sowie generelle Unternehmensinformationen interessiert und bitte um Zusendung per E-Mail.">
    </v-checkbox>
  </div>

  <div>
    <v-checkbox
      v-model="removeMyData"
      label="Bitte löschen Sie meine Daten aus Ihrem System.">
    </v-checkbox>
  </div>

  <p class="text-small">
    Selbstverständlich ist Ihre Einwilligung freiwillig und Sie können sie jederzeit für die Zukunft widerrufen. Durch den Widerruf entstehen Ihnen keine Nachteile.
  </p>

  <p class="text-small mb-2">
    Bitte Widerruf an: <a href="mailto:info@hc-i.de">info@hc-i.de</a> oder per Fax an: 06195 – 977929
  </p>
</div>
</template>

<script>
export default {
  name: "ContactFields",

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      moreProperties: false,
      newsletter: false,
      removeMyData: false,
      dsn: "",
      kennung: ""
    };
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    }
  },

  mounted() {
    this.$root.contactForm.registerField(this);

    if (window.location.search) {
      const params = new URLSearchParams(window.location.search.slice(1));
      this.dsn = params.get("DSN");
      this.kennung = params.get("kennung");
    }
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      this.$root.contactForm.contact.formMoreProperties = this.moreProperties;
      this.$root.contactForm.contact.formNewsletter = this.newsletter;
      this.$root.contactForm.contact.removeMyData = this.removeMyData;
      this.$root.contactForm.contact.formKennung = this.kennung;
      this.$root.contactForm.contact.formDSN = this.dsn;
    }
  }
};
</script>
